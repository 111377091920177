import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';

const initialState = {
  isPayWithCard: '',
  stripePublicKey: ''
};

const payment = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    completeLoadPayment: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    completeBuyCard: (state) => {
      return {
        ...state,
      };
    },
  },
});

export const {
  completeLoadPayment,
  completeBuyCard,
} = payment.actions;

export default payment.reducer;

export const getSaleSeettings = (data) => async (dispatch) => {
  const config = {
    data,
  };
  const response = await http.post(`${window.paymentApiPath}/sale-settings`, config);
  return dispatch(completeLoadPayment(response));
};

export const createClientCreditCard = (data) => async (dispatch) => {
  const config = { data };
  const response = await http.post(`${window.paymentApiPath}/add-client-card`, config);
  return dispatch(completeLoadPayment(response));
};
export const verifyClientCreditCard = (data) => async (dispatch) => {
  const config = { data };
  const response = await http.post(`${window.paymentApiPath}/verify-client-card`, config);
  return dispatch(completeLoadPayment(response));
};

export const readClientCreditCards = (clientId) => async (dispatch) => {
  const response = await http.get(`${window.paymentApiPath}/get-client-cards/${clientId}`);
  return dispatch(completeLoadPayment(response));
};

export const paymentWithClientCreditCard = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/service-charge`, config);
  return dispatch(completeLoadPayment(response));
};

export const createClientForBooking = (data = {}) => async (dispatch) => {
  const config = { data: { ...data, forBooking: true} };
  const response = await http.post(`${window.paymentApiPath}/client/create`, config);
  return dispatch(completeLoadPayment(response));
};

export const deleteCreditCard = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/delete-client-card`, config);
  return dispatch(completeLoadPayment(response));
}

export const createClientCharge = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/service-charge`, config);
  return dispatch(completeLoadPayment(response));
};

export const readCharges = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/get-payments`, config);
  return dispatch(completeLoadPayment(response));
};

export const readInvoices = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/get-invoices`, config);
  return dispatch(completeLoadPayment(response));
};

export const readSubscribe = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.paymentApiPath}/get-service-subscription`, config);
  return dispatch(completeLoadPayment(response));
};

export const buyGiftCard = (data) => async (dispatch) => {
  const config = {
    data,
  };
  const response = await http.post(`${window.baseApiPath}/gift-card/buying-gift-card`, config);
  return dispatch(completeBuyCard(response));
};