/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';
import { makeOption } from '../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const staff = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    completeLoadStaff: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: {
          ...currValue,
          ...makeOption(currValue),
          label: currValue.fullName,
        },
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedStaff: (state, { payload }) => {
      const ids = payload.staff.map(({ id }) => id);
      const byId = payload.staff.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: {
          ...currValue,
          ...makeOption(currValue),
          label: currValue.fullName,
        },
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadOneStaff: (state, { payload }) => {
      let ids = [...state.ids];
      if (ids.indexOf(payload.id) === -1) {
        ids = [...ids, payload.id];
      }
      return {
        ...state,
        ids,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeCreateStaff: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
            label: payload.fullName,
          },
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateStaff: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
            label: payload.fullName,
          },
        },
      };
    },
    completeArchivingOneStaff: (state, { payload }) => {
      const { [payload]: deletedStaff, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
    completeRestoreStaff: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
            label: payload.fullName,
          },
        },
        ids: [...state.ids, payload.id],
      };
    },
  },
});

export const {
  completeLoadStaff,
  completeLoadSortedStaff,
  completeLoadOneStaff,
  completeCreateStaff,
  completeUpdateStaff,
  completeArchivingOneStaff,
  completeRestoreStaff,
} = staff.actions;

export default staff.reducer;

export const loadStaff = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff`, config);

  return dispatch(completeLoadStaff(response));
};

export const loadStaffForBooking = options => async (dispatch) => {
  const response = await http.get(
    `${window.baseApiPath}/staff/get-for-booking`,
    { params: options },
  );

  return dispatch(completeLoadStaff(response));
};

export const loadStaffOnly = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/only`, config);

  return dispatch(completeLoadStaff(response));
};

export const loadDeletedStaff = options => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/get-deleted`, config);

  return dispatch(completeLoadSortedStaff(response));
};

export const loadSortedStaff = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/get-sorted`, config);

  return dispatch(completeLoadSortedStaff(response));
};

export const loadOneStaff = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/${id}`, config);

  return dispatch(completeLoadOneStaff(response));
};

export const createStaff = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/staff`, config);

  return dispatch(completeCreateStaff(response));
};

export const updateStaff = (data, id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/staff/${id}`, config);

  return dispatch(completeUpdateStaff(response));
};

export const disableLogInForStaff = (id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/staff/${id}/disable-log-in`, config);

  return dispatch(completeUpdateStaff(response));
};

export const enableLogInForStaff = (id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/staff/${id}/enable-log-in`, config);

  return dispatch(completeUpdateStaff(response));
};

export const archiveStaff = (id, currentClientTime) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: { currentClientTime },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/staff/${id}`, config);

  return dispatch(completeArchivingOneStaff(id));
};

export const restoreStaff = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/staff/${id}/restore`, config);

  return dispatch(completeRestoreStaff(response));
};

export const loadAllStaffForReport = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/for-report/get-all-staff`, config);

  return dispatch(completeLoadStaff(response));
};
